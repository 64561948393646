import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import "./Home.scss";
import { db } from "../base";

const QueueCardPlaceholder = () => (
  <div className="queue-location">
    <h3 className="queue-location__name">Loading...</h3>
    <ul className="queue-list">
      {["Au"].map((name, i) => (
        <li key={i} className="queue-list__item queue-list__item--placeholder">
          <h1 className="abbr">{name}</h1>
          <p className="full-name">Loading...</p>
        </li>
      ))}
    </ul>
  </div>
);

class Home extends Component {
  state = {
    isLoadingQueues: false,
    queues: []
  };

  componentDidMount() {
    if (!!this.props.organizationId) {
      this.fetchQueues();
    }
  }

  fetchQueues = () => {
    this.setState({ isLoadingQueues: true });

    db.collection("organizations")
      .doc(this.props.organizationId)
      .collection("queues")
      .get()
      .then(snapshot => {
        let allQueues = snapshot.docs.map(doc => {
          const { locationName, name } = doc.data();
          return {
            id: doc.id,
            locationName,
            name
          };
        });
        const queues = allQueues.reduce((acc, curr) => {
          if (!acc[curr.locationName]) acc[curr.locationName] = []; // Initialize empty set
          acc[curr.locationName].push(curr);
          return acc;
        }, {});
        this.setState({
          queues,
          isLoadingQueues: false
        });
      });
  };

  renderQueues = _ => {
    if (this.state.isLoadingQueues) {
      return <QueueCardPlaceholder />;
    } else if (Object.keys(this.state.queues).length === 0) {
      return (
        <div className="no-queue-message">
          <p>
            A <span className="accent">Queue</span> is collection of tickets
            waiting to be fulfilled.
          </p>
          <p>
            Each queue is a pairing of a Square Location with Square Item
            Categories. This customization allows you to send different parts of
            a purchase to different parts of your restaurant--the drinks to the
            bar and food to the kitchen, for example. All queues are synced to a
            real-time database by default so as soon as Square lets us know that
            an order has come in, the ticket appears. No need to refresh or
            reload.
          </p>
          <p>
            Marking a ticket as complete in one queue has no effect on tickets
            in other queues--regardless if they came from the same order or not.
            We keep track of all ticket completion times. Soon you will be able
            to segment these times by both time and item to see how your team is
            doing.
          </p>
          <p>
            You haven't made a <span className="accent">Queue</span> yet.
          </p>
          <Link to="/queues/new">
            <button>Create Queue</button>
          </Link>
        </div>
      );
    } else {
      return Object.keys(this.state.queues).map((locationName, i) => (
        <div className="queue-location" key={i}>
          <h3 className="queue-location__name">{locationName}</h3>
          <ul className="queue-list">
            {this.state.queues[locationName].map(({ name, id }) => (
              <li key={id} className="queue-list__item">
                <Link to={`/queues/${id}`}>
                  <h1 className="abbr">{name.substring(0, 2)}</h1>
                  <p className="full-name">{name}</p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ));
    }
  };

  renderWelcome = () => (
    <div className="welcome-message">
      <h1>Welcome!</h1>
      <p>
        To get started, we will need you to sign into your Square account. In
        doing so, you will grant this app permissions to read details about your
        merchant profile, items, and orders as well as subscribe to a webhook.
        This webhook, which fires each time your Square account receives a
        payment, allows us to analyze the order, build a ticket and push it to a
        tailor-made queue. To learn more about the scope of these permissions
        please reference the{" "}
        <a href="https://docs.connect.squareup.com/api/connect/v1#navsection-webhooks">
          Square API documentation.
        </a>
      </p>
      <a
        href={`https://us-central1-tickets-dev-c6569.cloudfunctions.net/authorize?uid=${
          this.props.userId
        }`}
      >
        <button>Connect to Square</button>
      </a>
    </div>
  );

  render() {
    const { name, email } = this.props;

    return (
      <div className="home">
        <aside className="home__sidebar">
          <div className="home__sidebar__top">
            <div className="logo">
              <Link to="/">
                <Logo
                  style={{
                    fill: "#FFF",
                    stroke: "none",
                    maxWidth: 28,
                    margin: "0 0 1rem"
                  }}
                />
              </Link>
            </div>
            <div className="merchant">{name || email}</div>
            <Link to="/logout" className="logout">
              Sign Out
            </Link>
          </div>
        </aside>

        <main className="home__content">
          <section className="home__content__queues">
            {this.props.organizationId ? (
              <React.Fragment>
                <header>
                  <h2 className="title">Queues</h2>
                  <div className="actions">
                    <Link to="/queues/new" className="create-queue">
                      <span className="plus-icon">+</span>
                    </Link>
                  </div>
                </header>
                {this.renderQueues()}
              </React.Fragment>
            ) : (
              this.renderWelcome()
            )}
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
