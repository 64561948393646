import React from "react";
import "./LittleFerryLoader.scss";

// taken from https://codepen.io/andersschmidt/pen/NPVPYP

const LittleFerryLoader = _ => (
  <div id="ferry" style={{ transform: "scale(3)" }}>
    <div id="chimney" />
    <div id="waves" />
  </div>
);

export default LittleFerryLoader;
