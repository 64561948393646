import React from "react";

const AirplaneLoader = _ => {
  return (
    <svg
      className="airplane-loader"
      viewBox="0 0 1300 600"
      style={{ maxWidth: 720 }}
    >
      <path
        id="planePath"
        className="planePath"
        d="M7.62,269.62S142,350.88,298.25,347.75s204-28.42,287.5-56.25c93.75-31.25,230.56-133.44,353.13-59.38,145.77,88.09,97.64,223.84,15.23,245.82C843.38,507.47,679,419,766.33,285.35c78.74-120.57,469.42-72,594.42-9.47"
        transform="translate(-7.37 -205.25)"
        stroke="none"
        fill="none"
      />
      {/* plane fill matches undraw illustrations */}
      <g id="plane" fill="#6c63ff">
        <path d="M89.3,30.8C89.3,30.8,89.3,30.8,89.3,30.8C89.3,30.8,89.3,30.8,89.3,30.8C89.3,30.8,89.3,30.7,89.3,30.8c0-0.1,0-0.1,0-0.1  c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0  c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0l-77.1,17c-0.4,0.1-0.6,0.4-0.6,0.8  c0,0.4,0.4,0.7,0.8,0.7l12.2,0.2v19.4c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1  c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0l8.2-4.8l2.2,5.1  c0.1,0.2,0.3,0.4,0.5,0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5-0.1L89,31.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1  c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1C89.3,30.9,89.3,30.9,89.3,30.8z   M27.6,49.4C27.6,49.4,27.6,49.4,27.6,49.4c-0.1,0.1-0.2,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0  c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l-1.9,10.2V48.7l29.6-7.9L27.6,49.4z M36.2,67.9l-7.3-17.3l55.3-17.5L36.2,67.9z" />
      </g>
      <animateMotion
        xlinkHref="#plane"
        dur="7s"
        repeatCount="indefinite"
        rotate="auto"
      >
        <mpath xlinkHref="#planePath" />
      </animateMotion>
    </svg>
  );
};

export default AirplaneLoader;
