import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import app, { db } from "./base";

import Home from "./Home";
import Queue from "./Queue";
import SampleQueue from "./Queue/Sample";
import { CreateQueueForm } from "./QueueForm";
import Loader from "./Queue/Loader";

import LogIn from "./LogIn";
import LogOut from "./LogOut";
import SignUp from "./SignUp";

class App extends Component {
  state = { loading: true, authenticated: false, currentUser: null };

  componentDidMount() {
    this.subscribeToAuthState();
  }

  subscribeToAuthState = () => {
    app.auth().onAuthStateChanged(this.handleAuthChange);
  };

  getUser = uid => {
    return db
      .collection("users")
      .doc(uid)
      .get()
      .then(snapshot => {
        this.setState({
          authenticated: true,
          currentUser: snapshot.data(),
          loading: false
        });
      });
  };

  handleAuthChange = authUser => {
    if (authUser) {
      this.getUser(authUser.uid);
    } else {
      this.setState({
        authenticated: false,
        currentUser: null,
        loading: false
      });
    }
  };

  render() {
    const { authenticated, loading, currentUser } = this.state;
    const { organizationId, uid: userId, name, email } = authenticated
      ? currentUser
      : { uid: null, organizationId: null, name: null, email: "" };

    if (loading) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    return (
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={Home}
            authenticated={authenticated}
            userId={userId}
            organizationId={organizationId}
            name={name}
            email={email}
          />
          <PrivateRoute
            exact
            path="/queues/new"
            component={CreateQueueForm}
            authenticated={authenticated}
            userId={userId}
            organizationId={organizationId}
          />
          <Route exact path="/demo" component={SampleQueue} />
          <PrivateRoute
            exact
            path="/queues/:queueId"
            component={Queue}
            authenticated={authenticated}
            organizationId={organizationId}
          />
          <Route
            exact
            path="/login"
            render={() => (authenticated ? <Redirect to="/" /> : <LogIn />)}
          />
          <Route exact path="/logout" render={LogOut} />
          <Route
            exact
            path="/signup"
            render={() => (authenticated ? <Redirect to="/" /> : <SignUp />)}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
