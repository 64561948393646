import React from "react";
import { noContentIllustrations } from "./illustrations";

// downloaded from https://undraw.co/illustrations
// modified for react 2018-09-15
const illustrationIndex = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 2,
  5: 1,
  6: 3
}[new Date().getDay()];

const NoContent = _ => {
  const Illustration = noContentIllustrations[illustrationIndex];
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "3rem auto 0"
      }}
    >
      <Illustration />
      <aside
        style={{
          maxWidth: 360,
          padding: "1rem",
          margin: "auto",
          textAlign: "center"
        }}
      >
        <h4>All caught up!</h4>
        <p>You are up to date with the orders for this queue.</p>
      </aside>
    </section>
  );
};

export default NoContent;
