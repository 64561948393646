import React from "react";
import { loadingAnimations } from "./illustrations";

// downloaded from https://undraw.co/illustrations
// modified for react 2018-09-15

const dayIndex = new Date().getDay() % 2; // selects animation 0 or 1

const NoContent = _ => {
  const Animation = loadingAnimations[dayIndex];
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "3rem auto 0"
      }}
    >
      <Animation />
      <aside
        style={{
          maxWidth: 360,
          padding: "1rem",
          margin: "auto",
          textAlign: "center"
        }}
      >
        <h4>Loading...</h4>
      </aside>
    </section>
  );
};

export default NoContent;
