import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { db } from "../base";

import InputFeedback from "./InputFeedback";
import CheckboxGroup from "./CheckboxGroup";
import RadioButtonGroup from "./RadioButtonGroup";

import "./CreateQueue.scss";

class CreateQueueForm extends Component {
  state = {
    isLoadingCategories: false,
    categoryOptions: [],
    isLoadingLocations: false,
    locationOptions: []
  };

  componentDidMount() {
    this.fetchLocations();
  }

  fetchCategories = async locationId => {
    this.setState({ isLoadingCategories: true });

    const categories = await fetch(
      `${
        process.env.REACT_APP_FUNC_DOMAIN
      }/squareLocationCategories?organizationId=${
        this.props.organizationId
      }&locationId=${locationId}`
    ).then(res => res.json());

    return this.setState({
      categoryOptions: categories.map(c => ({ label: c.name, value: c.id })),
      isLoadingCategories: false
    });
  };

  fetchLocations = async () => {
    this.setState({ isLoadingLocations: true });

    const locations = await fetch(
      `${process.env.REACT_APP_FUNC_DOMAIN}/squareLocations?organizationId=${
        this.props.organizationId
      }`
    )
      .then(res => res.json())
      .then(({ locations }) => locations);

    this.setState({
      locationOptions: locations.map(l => ({ label: l.name, value: l.id })),
      isLoadingLocations: false
    });
  };

  isLoading = () =>
    this.state.isLoadingLocations || this.state.isLoadingCategories;

  clearOptions = () => {
    this.setState({ categoryOptions: [], isLoadingCategories: false });
  };

  createQueue = (values, formHelper) => {
    const { userId, organizationId, history } = this.props;

    const locationName = this.state.locationOptions.find(
      opt => opt.value === values.locationId
    ).label;
    const categoryNames = this.state.categoryOptions
      .filter(opt => values.categoryIds.includes(opt.value))
      .map(opt => opt.label);

    const queue = {
      ...values,
      locationName,
      categoryNames,
      createdBy: userId
    };

    return db
      .collection("organizations")
      .doc(organizationId)
      .collection("queues")
      .add(queue)
      .then(({ id: queueId }) => {
        return history.push(`/queues/${queueId}`);
      });
  };

  render() {
    const {
      locationOptions,
      isLoadingLocations,
      categoryOptions,
      isLoadingCategories
    } = this.state;

    return (
      <div>
        <header className="headerbar">
          <nav className="headerbar__content">
            <ul className="headerbar__content__nav headerbar__content__nav--left">
              <li className="headerbar__content__nav__item">
                <Link to="/" className="headerbar-close">
                  <svg
                    viewBox="0 -1 18 18"
                    height="18"
                    width="18"
                    className="svg-icon svg-icon-close-x"
                  >
                    <g
                      stroke="#70767C"
                      strokeWidth="2"
                      fill="none"
                      fillRule="evenodd"
                      className="svg-icon__stroke"
                    >
                      <path d="M17 0L1 16M1 0l16 16" />
                    </g>
                  </svg>
                </Link>
              </li>
            </ul>
          </nav>
          <h1 className="headerbar__title">Create Queue</h1>
          <nav className="headerbar__content">
            <ul className="headerbar__content__nav headerbar__content__nav--right" />
          </nav>
        </header>
        <main>
          <Formik
            initialValues={{
              name: "",
              locationId: "",
              categoryIds: []
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              locationId: Yup.string().required("Required"),
              categoryIds: Yup.array().required("Must select at least one")
            })}
            onSubmit={this.createQueue}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleSubmit,
                handleReset,
                setFieldValue,
                setFieldTouched
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <fieldset className="input-group">
                    <legend>Name</legend>
                    <Field
                      name="name"
                      placeholder="Queue Name"
                      className={classNames("text-input", "input-field", {
                        error: !!errors.name && !!touched.name
                      })}
                    />
                    {touched.name && <InputFeedback error={errors.name} />}
                  </fieldset>

                  <RadioButtonGroup
                    name="locationId"
                    label="Location"
                    value={values.locationId}
                    error={errors.locationId}
                    touched={touched.locationId}
                    fetchDependent={this.fetchCategories}
                    options={locationOptions}
                    isLoadingOptions={isLoadingLocations}
                  />

                  <CheckboxGroup
                    name="categoryIds"
                    label="Categories"
                    value={values.categoryIds}
                    error={errors.categoryIds}
                    touched={touched.categoryIds}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={categoryOptions}
                    isLoadingOptions={isLoadingCategories}
                    noOptionMessage="Select a location to load available item categories"
                  />

                  <div className="create-queue-form__actions">
                    <button type="submit" disabled={isSubmitting}>
                      Save
                    </button>
                    <button
                      type="button"
                      className="outline"
                      onClick={() => {
                        this.clearOptions();
                        return handleReset();
                      }}
                      disabled={!dirty || isSubmitting || this.isLoading()}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </main>
      </div>
    );
  }
}

export default CreateQueueForm;
