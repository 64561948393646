import React from "react";
import { svgStyles } from "./styles";

const AirBalloonFloating = _ => (
  <svg id="air-balloon-floating" viewBox="0 0 1165.21 858" style={svgStyles}>
    <defs>
      <linearGradient
        id="f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
        x1="739.11"
        y1="600.75"
        x2="739.11"
        y2="35"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="f3ab6348-f00d-4f48-ac7c-85d02bfc6ffb"
        x1="686.68"
        y1="717.39"
        x2="686.68"
        y2="670.55"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="0b05ffbc-780b-4703-ab6e-f12613b08fc6"
        x1="645.13"
        y1="725.98"
        x2="645.13"
        y2="664.77"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="fefdaf25-1efa-4566-a839-7466210dd781"
        x1="748.64"
        y1="691.12"
        x2="748.64"
        y2="649.87"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="00faf9d1-f067-4649-a9f7-133b0e619575"
        x1="726.3"
        y1="801.01"
        x2="726.3"
        y2="704.54"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="e0f12371-53e2-4b63-954f-7529306c0fcf"
        x1="765.68"
        y1="709.46"
        x2="765.68"
        y2="663.19"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="0828e378-0415-49ac-805e-ffac983dd77f"
        x1="725"
        y1="858"
        x2="725"
        y2="712.51"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="a59f3b9d-1771-4339-a46e-b16d2b7b7a75"
        x1="325.86"
        y1="102.02"
        x2="325.86"
        y2="41.73"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="127b93fc-e9a8-4a28-b3e8-ddf2259882c5"
        x1="314.63"
        y1="112.46"
        x2="314.63"
        y2="96.95"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="0726a656-e16d-4980-b3fe-a86ba469ed49"
        x1="96.86"
        y1="558.16"
        x2="96.86"
        y2="473.87"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="09a3f343-371f-47c8-af4b-b7896fe83138"
        x1="85.49"
        y1="580.32"
        x2="85.49"
        y2="558.64"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="91620f7c-415a-4efb-8775-05b0b41b95e1"
        x1="401.86"
        y1="498.21"
        x2="401.86"
        y2="402"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="1ac82200-36c7-4144-8a58-4670deda2f7e"
        x1="390.42"
        y1="526.19"
        x2="390.42"
        y2="501.45"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="be26d75a-0455-4f88-8e02-7405c5fe6a65"
        x1="281.25"
        y1="152.04"
        x2="281.25"
        y2="80.66"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="0b2f09ef-1575-4fe8-8a39-bc8009e41251"
        x1="269.97"
        y1="167.89"
        x2="269.97"
        y2="149.53"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="e4790bd4-5801-4457-b473-8cffc98e274c"
        x1="35.42"
        y1="97.02"
        x2="35.42"
        y2="46.61"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="3d7619d0-4e5d-43de-b852-961e26921f74"
        x1="24.26"
        y1="102.64"
        x2="24.26"
        y2="89.68"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="13b49d86-3a6c-4373-8a2b-23927da3a66d"
        x1="452.42"
        y1="84.02"
        x2="452.42"
        y2="33.61"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="e13d1787-a849-404d-9971-b1babf765573"
        x1="441.26"
        y1="89.64"
        x2="441.26"
        y2="76.68"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="8d0e9b9d-b502-4368-bee2-132b20c20719"
        x1="102.82"
        y1="287.6"
        x2="102.82"
        y2="212.78"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="2bbe2ca3-b086-4129-9122-2e4a5aa8bf64"
        x1="91.51"
        y1="305.14"
        x2="91.51"
        y2="285.9"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="6be94b36-c083-4e0b-93be-f3da3b19fa1e"
        x1="999.82"
        y1="89.49"
        x2="999.82"
        y2="19"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="cd7958c8-0c10-490b-9e34-7b85f0d28caa"
        x1="988.54"
        y1="104.92"
        x2="988.54"
        y2="86.79"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="de1c6a66-0b91-463d-a557-a0141dbc776a"
        x1="1136.82"
        y1="98.6"
        x2="1136.82"
        y2="23.78"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="1fff592f-6693-4eb3-9171-bb32ff9687db"
        x1="1125.51"
        y1="116.14"
        x2="1125.51"
        y2="96.9"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="4e6c8e0b-171b-414f-b772-a9b03415ed1d"
        x1="1070.86"
        y1="285.65"
        x2="1070.86"
        y2="219"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="9eb5e881-07ee-4ce5-baa7-4927dd5ad0d0"
        x1="1059.6"
        y1="299.19"
        x2="1059.6"
        y2="282.05"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="b47c5690-0072-4895-bfce-7e12bed9c37f"
        x1="1140.25"
        y1="492.61"
        x2="1140.25"
        y2="419"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
      <linearGradient
        id="9c3c48fe-271f-491e-9bd0-9e0c46ee695c"
        x1="1128.95"
        y1="509.55"
        x2="1128.95"
        y2="490.62"
        xlinkHref="#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d"
      />
    </defs>
    <title>floating</title>
    <path
      d="M1014.54,317.87c0,156.23-130.51,282.87-269.47,282.87S463.68,474.1,463.68,317.87,606.11,35,745.07,35,1014.54,161.65,1014.54,317.87Z"
      transform="translate(-10.88 -19)"
      fill="url(#f2f4f6e9-6b95-42bc-91e2-935d3f95e99d)"
    />
    <path
      d="M1001.64,317.87c0,148.91-124.4,269.63-256.86,269.63S476.58,466.79,476.58,317.87,612.33,48.24,744.79,48.24,1001.64,169,1001.64,317.87Z"
      transform="translate(-10.88 -19)"
      fill="#6c63ff"
    />
    <path
      d="M705.6,687.58c0-9.41-8.47-17-18.92-17s-18.92,7.63-18.92,17c0,7.93,6,14.58,14.19,16.48v13.33h9.46V704.06C699.57,702.16,705.6,695.52,705.6,687.58Z"
      transform="translate(-10.88 -19)"
      fill="url(#f3ab6348-f00d-4f48-ac7c-85d02bfc6ffb)"
    />
    <path
      d="M637.46,689.71,618.3,670.55l-.38.38,0-.09c-2-4.24-5.4-6.86-7.52-5.85s-2.2,5.28-.17,9.52a16.23,16.23,0,0,0,1.55,2.59l-2,2s49,49,68.14,46.85l3.61-13.36S665.15,713.13,637.46,689.71Z"
      transform="translate(-10.88 -19)"
      fill="url(#0b05ffbc-780b-4703-ab6e-f12613b08fc6)"
    />
    <circle cx="675.34" cy="669.16" r="15.76" fill="#f8c198" />
    <rect x="671.4" y="680.99" width="7.88" height="15.76" fill="#f8c198" />
    <rect
      x="659.58"
      y="690.84"
      width="31.52"
      height="70.93"
      rx="8"
      ry="8"
      fill="#605b38"
    />
    <path
      d="M679.71,711.27s-15.16.54-40.78-21.14L621.2,672.4l-7.88,7.88s45.32,45.32,63,43.35Z"
      transform="translate(-10.88 -19)"
      fill="#605b38"
    />
    <ellipse
      cx="617.26"
      cy="674.37"
      rx="3.94"
      ry="7.88"
      transform="translate(-241.43 313.32) rotate(-25.56)"
      fill="#f8c198"
    />
    <circle
      cx="748.64"
      cy="670.49"
      r="20.63"
      fill="url(#fefdaf25-1efa-4566-a839-7466210dd781)"
    />
    <circle cx="748.64" cy="670.49" r="18.65" fill="#f8c198" />
    <rect x="743.97" y="684.48" width="9.33" height="18.65" fill="#f8c198" />
    <path
      d="M772.46,713.56h-23.1a7.89,7.89,0,0,0-6.44,3.36c-17.55,2.28-53.66,5.8-57.32-3.36l-1.68,1.26a18.46,18.46,0,0,0-1.23-3.35c-2.32-4.84-6.16-7.83-8.58-6.67s-2.51,6-.19,10.86a17.3,17.3,0,0,0,3,4.42l-1,.77s36.93,15.55,65.59,13.61v58.67a7.91,7.91,0,0,0,7.89,7.89h23.1a7.91,7.91,0,0,0,7.89-7.89V721.44A7.91,7.91,0,0,0,772.46,713.56Z"
      transform="translate(-10.88 -19)"
      fill="url(#00faf9d1-f067-4649-a9f7-133b0e619575)"
    />
    <rect
      x="729.98"
      y="696.14"
      width="37.3"
      height="83.93"
      rx="8"
      ry="8"
      fill="#53ad9b"
    />
    <path
      d="M751.35,717.05s-59.45,9.74-64.11-1.92l-9.33,7s44.3,18.65,72.27,11.66S751.35,717.05,751.35,717.05Z"
      transform="translate(-10.88 -19)"
      fill="#53ad9b"
    />
    <ellipse
      cx="680.25"
      cy="715.14"
      rx="4.66"
      ry="9.33"
      transform="translate(-252.86 344.48) rotate(-25.56)"
      fill="#f8c198"
    />
    <path
      d="M741.29,669.35a2.36,2.36,0,0,1,2.34,1.37c1.92-4.2,6.46-6.77,11-7.36s9.21.51,13.58,2c5.59,1.91,11.06,4.55,15.27,8.69s7,10,6.51,15.87c-.38,4.33-2.47,8.3-4.53,12.12-1.27,2.37-2.65,4.83-4.92,6.26-4.74,3-11.46-.17-14-5.16-.84-1.65-1.36-3.45-2.29-5-2.81-4.84-8.63-6.82-13.64-9.33C741.85,684.36,741.16,678.24,741.29,669.35Z"
      transform="translate(-10.88 -19)"
      fill="url(#e0f12371-53e2-4b63-954f-7529306c0fcf)"
    />
    <rect
      x="640.8"
      y="712.51"
      width="168.4"
      height="145.49"
      fill="url(#0828e378-0415-49ac-805e-ffac983dd77f)"
    />
    <rect x="645.79" y="712.51" width="159.59" height="137.92" fill="#f5f5f5" />
    <line
      x1="803.41"
      y1="712.51"
      x2="810.07"
      y2="586.48"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="649.73"
      y1="712.51"
      x2="640.72"
      y2="586.48"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M743.7,688.55A18.64,18.64,0,0,1,760.93,670c-.47,0-.94-.07-1.42-.07a18.65,18.65,0,0,0,0,37.3c.48,0,.95,0,1.42-.07A18.64,18.64,0,0,1,743.7,688.55Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M672.75,686.66A18.64,18.64,0,0,1,690,668.08c-.47,0-.94-.07-1.42-.07a18.65,18.65,0,0,0,0,37.3c.48,0,.95,0,1.42-.07A18.64,18.64,0,0,1,672.75,686.66Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M683.11,701a18.87,18.87,0,0,1,2.82,2.3c3.35,4.17-3,10.63-.62,15.43,1.05,2.12,3.55,3.2,4.67,5.28,2.13,3.95-1.44,9.73,1.65,13,.83.87,2,1.38,2.65,2.4,1.25,2-.5,4.66-.22,7,.35,3,3.63,4.6,6.46,5.66,2.41.9,5.57,1.62,7.21-.37.79-1,.94-2.31,1.68-3.32,1.38-1.89,4.16-1.9,6.19-3.06,3.15-1.8,3.92-6,3.92-9.61s-.36-7.55,1.62-10.6c2.24-3.46,6.89-4.75,9.13-8.21,3.39-5.26-.35-12.61,2.07-18.38.84-2,2.38-3.69,2.88-5.79.83-3.47-1.39-7-4-9.33s-5.87-4.16-8.08-7c-1.55-2-2.56-4.35-4.39-6.06-4.44-4.14-11.5-2.66-17.43-4-5.49-1.19-10.55-4.95-16.14-4.32-4.89.55-8.71,4.31-12.12,7.86a6.29,6.29,0,0,0-1.9,2.92c-1,4.83,3.69,9.8,5.75,13.34C679.54,690.88,678.73,697.33,683.11,701Z"
      transform="translate(-10.88 -19)"
      fill="#fccc63"
    />
    <g opacity="0.2">
      <path
        d="M673,673.79c3.41-3.55,7.23-7.31,12.12-7.86,5.58-.63,10.65,3.13,16.14,4.32,5.93,1.29,13-.19,17.43,4,1.83,1.71,2.84,4.1,4.39,6.06,2.21,2.79,5.43,4.58,8.08,7a12.9,12.9,0,0,1,3.95,5.88c0-.11.07-.22.1-.33.83-3.47-1.39-7-4.05-9.33s-5.87-4.16-8.08-7c-1.55-2-2.56-4.35-4.39-6.06-4.44-4.14-11.5-2.66-17.43-4-5.49-1.19-10.55-4.95-16.14-4.32-4.89.55-8.71,4.31-12.12,7.86a6.29,6.29,0,0,0-1.9,2.92,7.58,7.58,0,0,0,.13,3.32A7.35,7.35,0,0,1,673,673.79Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M694.4,743.43a9.29,9.29,0,0,0-.34,3,4.32,4.32,0,0,0,.27,1.08C694.65,746.09,695,744.66,694.4,743.43Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M690.57,729.59a18.28,18.28,0,0,0-.12,5A18.73,18.73,0,0,0,690.57,729.59Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M731.69,712.36a46.51,46.51,0,0,0-.17-6.52A46.24,46.24,0,0,0,731.69,712.36Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M719.48,736.82c0-.36,0-.72,0-1.07,0-.18,0-.36,0-.54C719.49,735.75,719.48,736.29,719.48,736.82Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M686.66,708.42c-.72,3.15-2.55,6.61-1.65,9.55C685.71,714.68,687.63,711.21,686.66,708.42Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <path
      d="M742.7,670.32a2.22,2.22,0,0,1,2.2,1.29c1.81-4,6.08-6.38,10.4-6.94s8.68.48,12.8,1.89c5.27,1.8,10.42,4.29,14.39,8.19s6.62,9.41,6.13,15c-.36,4.08-2.33,7.82-4.27,11.42-1.2,2.23-2.5,4.55-4.64,5.9-4.47,2.81-10.8-.16-13.21-4.86-.79-1.55-1.28-3.25-2.16-4.75-2.65-4.56-8.14-6.42-12.85-8.79C743.22,684.48,742.57,678.71,742.7,670.32Z"
      transform="translate(-10.88 -19)"
      fill="#263238"
    />
    <path
      d="M742.7,672.22a2.22,2.22,0,0,1,2.2,1.29c1.81-4,6.08-6.38,10.4-6.94s8.68.48,12.8,1.89c5.27,1.8,10.42,4.29,14.39,8.19a20,20,0,0,1,6.15,12.66c.33-5.41-2.28-10.74-6.15-14.55s-9.12-6.39-14.39-8.19c-4.12-1.41-8.49-2.45-12.8-1.89s-8.59,3-10.4,6.94a2.22,2.22,0,0,0-2.2-1.29c0,.81,0,1.6,0,2.36C742.69,672.53,742.69,672.37,742.7,672.22Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <rect x="692.76" y="577.96" width="59.6" height="20.81" fill="#6c63ff" />
    <g opacity="0.2">
      <path
        d="M744.79,47.3c-3.16,0-6.31.09-9.47.23,128.86,5.56,247.41,124,247.41,269.4S864.17,580.77,735.32,586.33c3.16.14,6.32.23,9.47.23,132.45,0,256.86-120.72,256.86-269.63S877.24,47.3,744.79,47.3Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M491.71,317.87c0-146.78,131.9-266.17,262.53-269.55-1.89,0-3.78-.08-5.67-.08C616.12,48.24,480.36,169,480.36,317.87S616.12,587.5,748.57,587.5c1.89,0,3.78,0,5.67-.08C623.62,584,491.71,464.66,491.71,317.87Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="639.78" y="530.66" width="171.24" height="55.82" fill="#6c63ff" />
    <path
      d="M650.65,67.88s-203.4,232.49,0,500l67.17-.23s-185.43-221-8.51-516.55L676.2,58.65Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M827.57,64.33s203.4,236,0,503.54l-67.17-.23s185.43-222.88,8.51-518.44L802,55.81Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M356,71.87C356,88.52,341.72,102,326.52,102s-30.79-13.5-30.79-30.14,15.58-30.14,30.79-30.14S356,55.23,356,71.87Z"
      transform="translate(-10.88 -19)"
      fill="url(#a59f3b9d-1771-4339-a46e-b16d2b7b7a75)"
    />
    <path
      d="M354.59,71.87c0,15.87-13.61,28.73-28.1,28.73s-29.35-12.86-29.35-28.73S312,43.14,326.49,43.14,354.59,56,354.59,71.87Z"
      transform="translate(-10.88 -19)"
      fill="#6c63ff"
    />
    <rect
      x="305.42"
      y="96.95"
      width="18.43"
      height="15.5"
      fill="url(#127b93fc-e9a8-4a28-b3e8-ddf2259882c5)"
    />
    <rect x="305.97" y="96.95" width="17.46" height="14.7" fill="#6c63ff" />
    <line
      x1="323.21"
      y1="96.95"
      x2="323.94"
      y2="83.52"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="306.4"
      y1="96.95"
      x2="305.41"
      y2="83.52"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M321,117.22a1,1,0,0,0,0,.32.45.45,0,0,0,0,.11A.65.65,0,0,0,321,117.22Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M320.55,115.75a1.9,1.9,0,0,0,0,.53A1.94,1.94,0,0,0,320.55,115.75Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M323.72,116.52s0-.08,0-.11v-.06C323.72,116.41,323.72,116.46,323.72,116.52Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="311.1" y="82.62" width="6.52" height="2.22" fill="#6c63ff" />
    <g opacity="0.2">
      <path
        d="M326.49,43l-1,0c14.1.59,27.07,13.22,27.07,28.71s-13,28.12-27.07,28.71l1,0c14.49,0,28.1-12.86,28.1-28.73S341,43,326.49,43Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M298.8,71.87c0-15.64,14.43-28.36,28.72-28.72h-.62c-14.49,0-29.35,12.86-29.35,28.73s14.85,28.73,29.35,28.73h.62C313.23,100.24,298.8,87.52,298.8,71.87Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="305.31" y="77.57" width="18.74" height="5.95" fill="#6c63ff" />
    <path
      d="M316.19,45.23s-22.26,24.77,0,53.28l7.35,0s-20.29-23.55-.93-55l-3.62.81Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M335.54,44.86s22.26,25.15,0,53.66l-7.35,0s20.29-23.75.93-55.25l3.62.71Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M139,516c0,23.28-20,42.15-41.22,42.15s-43-18.87-43-42.15,21.79-42.15,43-42.15S139,492.74,139,516Z"
      transform="translate(-10.88 -19)"
      fill="url(#0726a656-e16d-4980-b3fe-a86ba469ed49)"
    />
    <path
      d="M137,516c0,22.19-19,40.17-39.29,40.17s-41-18-41-40.17,20.77-40.17,41-40.17S137,493.83,137,516Z"
      transform="translate(-10.88 -19)"
      fill="#4d8af0"
    />
    <rect
      x="72.61"
      y="558.64"
      width="25.76"
      height="21.68"
      fill="url(#09a3f343-371f-47c8-af4b-b7896fe83138)"
    />
    <rect x="73.37" y="558.64" width="24.41" height="20.55" fill="#6c63ff" />
    <line
      x1="97.49"
      y1="558.64"
      x2="98.51"
      y2="539.87"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="73.98"
      y1="558.64"
      x2="72.6"
      y2="539.87"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M90,579.42a1.35,1.35,0,0,0-.05.45.63.63,0,0,0,0,.16A.91.91,0,0,0,90,579.42Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M89.44,577.36a2.65,2.65,0,0,0,0,.74A2.72,2.72,0,0,0,89.44,577.36Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M93.86,578.44c0-.05,0-.11,0-.16v-.08C93.86,578.28,93.86,578.36,93.86,578.44Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="80.56" y="538.6" width="9.12" height="3.1" fill="#4d8af0" />
    <g opacity="0.2">
      <path
        d="M97.73,475.7c-.48,0-1,0-1.45,0,19.71.83,37.85,18.48,37.85,40.14S116,555.18,96.28,556c.48,0,1,0,1.45,0,20.26,0,39.29-18,39.29-40.17S118,475.7,97.73,475.7Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M59,516c0-21.87,20.18-39.66,40.16-40.16h-.87c-20.26,0-41,18-41,40.17s20.77,40.17,41,40.17h.87C79.2,555.67,59,537.88,59,516Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="72.45" y="531.55" width="26.2" height="8.32" fill="#4d8af0" />
    <path
      d="M83.33,478.76s-31.12,34.64,0,74.5l10.28,0s-28.37-32.93-1.3-77l-5.07,1.13Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M110.4,478.24s31.12,35.17,0,75l-10.28,0S128.49,520,101.42,476l5.07,1Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M450,450.1c0,26.57-22.79,48.1-47.05,48.1s-49.13-21.54-49.13-48.1S378.64,402,402.9,402,450,423.54,450,450.1Z"
      transform="translate(-10.88 -19)"
      fill="url(#91620f7c-415a-4efb-8775-05b0b41b95e1)"
    />
    <path
      d="M447.7,450.1C447.7,475.43,426,496,402.86,496S356,475.43,356,450.1s23.7-45.85,46.83-45.85S447.7,424.78,447.7,450.1Z"
      transform="translate(-10.88 -19)"
      fill="#47e6b1"
    />
    <rect
      x="375.72"
      y="501.45"
      width="29.4"
      height="24.74"
      fill="url(#1ac82200-36c7-4144-8a58-4670deda2f7e)"
    />
    <rect x="376.59" y="501.45" width="27.87" height="23.45" fill="#f2f2f2" />
    <line
      x1="404.11"
      y1="501.45"
      x2="405.27"
      y2="480.01"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="377.28"
      y1="501.45"
      x2="375.71"
      y2="480.01"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M394.06,522.47a1.54,1.54,0,0,0-.06.51.72.72,0,0,0,0,.18A1,1,0,0,0,394.06,522.47Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M393.39,520.12a3,3,0,0,0,0,.85A3.1,3.1,0,0,0,393.39,520.12Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M398.44,521.35c0-.06,0-.12,0-.18v-.09C398.44,521.17,398.44,521.26,398.44,521.35Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="384.79" y="478.57" width="10.41" height="3.54" fill="#47e6b1" />
    <g opacity="0.2">
      <path
        d="M402.86,404.09c-.55,0-1.1,0-1.65,0,22.5.95,43.2,21.09,43.2,45.81s-20.7,44.87-43.2,45.81c.55,0,1.1,0,1.65,0,23.13,0,44.85-20.53,44.85-45.85S426,404.09,402.86,404.09Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M358.67,450.1c0-25,23-45.26,45.84-45.84h-1c-23.13,0-46.83,20.53-46.83,45.85S380.39,496,403.52,496h1C381.7,495.37,358.67,475.07,358.67,450.1Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="375.54" y="470.52" width="29.9" height="9.49" fill="#47e6b1" />
    <path
      d="M386.42,407.59s-35.52,39.54,0,85l11.73,0s-32.38-37.58-1.49-87.84L390.88,406Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M417.31,407s35.52,40.14,0,85.63l-11.73,0s32.38-37.9,1.49-88.17l5.78,1.13Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M316,116.35c0,19.71-16.47,35.69-34,35.69s-35.5-16-35.5-35.69,18-35.69,35.5-35.69S316,96.64,316,116.35Z"
      transform="translate(-10.88 -19)"
      fill="url(#be26d75a-0455-4f88-8e02-7405c5fe6a65)"
    />
    <path
      d="M314.37,116.35c0,18.79-15.69,34-32.41,34s-33.84-15.23-33.84-34,17.13-34,33.84-34S314.37,97.56,314.37,116.35Z"
      transform="translate(-10.88 -19)"
      fill="#47e6b1"
    />
    <rect
      x="259.34"
      y="149.53"
      width="21.25"
      height="18.36"
      fill="url(#0b2f09ef-1575-4fe8-8a39-bc8009e41251)"
    />
    <rect x="259.97" y="149.53" width="20.13" height="17.4" fill="#e6e6e6" />
    <line
      x1="279.86"
      y1="149.53"
      x2="280.7"
      y2="133.63"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="260.47"
      y1="149.53"
      x2="259.33"
      y2="133.63"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M275.61,170a1.17,1.17,0,0,0,0,.38.54.54,0,0,0,0,.14A.79.79,0,0,0,275.61,170Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M275.13,168.29a2.31,2.31,0,0,0,0,.63A2.36,2.36,0,0,0,275.13,168.29Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M278.78,169.2s0-.09,0-.14V169C278.78,169.07,278.78,169.14,278.78,169.2Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="265.9" y="132.56" width="7.52" height="2.63" fill="#47e6b1" />
    <g opacity="0.2">
      <path
        d="M282,82.21l-1.19,0c16.26.7,31.21,15.65,31.21,34s-15,33.29-31.21,34l1.19,0c16.71,0,32.41-15.23,32.41-34S298.68,82.21,282,82.21Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M250,116.35c0-18.52,16.64-33.58,33.12-34h-.72c-16.71,0-33.84,15.23-33.84,34s17.13,34,33.84,34h.72C266.68,149.93,250,134.87,250,116.35Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="259.21" y="126.59" width="21.6" height="7.04" fill="#47e6b1" />
    <path
      d="M270.09,84.81s-25.66,29.33,0,63.08l8.47,0S255.17,120,277.49,82.69l-4.18,1Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M292.41,84.36s25.66,29.78,0,63.53l-8.47,0s23.39-28.12,1.07-65.41l4.18.84Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M60,71.82C60,85.74,48.34,97,36,97S10.88,85.74,10.88,71.82,23.57,46.61,36,46.61,60,57.89,60,71.82Z"
      transform="translate(-10.88 -19)"
      fill="url(#e4790bd4-5801-4457-b473-8cffc98e274c)"
    />
    <path
      d="M58.82,71.82c0,13.27-11.09,24-22.89,24S12,85.09,12,71.82s12.1-24,23.9-24S58.82,58.55,58.82,71.82Z"
      transform="translate(-10.88 -19)"
      fill="#6c63ff"
    />
    <rect
      x="16.75"
      y="89.68"
      width="15.01"
      height="12.96"
      fill="url(#3d7619d0-4e5d-43de-b852-961e26921f74)"
    />
    <rect x="17.2" y="89.68" width="14.22" height="12.29" fill="#6c63ff" />
    <line
      x1="31.24"
      y1="89.68"
      x2="31.84"
      y2="78.44"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="17.55"
      y1="89.68"
      x2="16.75"
      y2="78.44"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M31.44,109.74a.83.83,0,0,0,0,.27.38.38,0,0,0,0,.1A.56.56,0,0,0,31.44,109.74Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M31.1,108.5a1.63,1.63,0,0,0,0,.44A1.67,1.67,0,0,0,31.1,108.5Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M33.67,109.15s0-.06,0-.1v0S33.67,109.1,33.67,109.15Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="21.38" y="77.69" width="5.31" height="1.85" fill="#6c63ff" />
    <g opacity="0.2">
      <path
        d="M35.93,47.7l-.84,0c11.48.5,22,11.05,22,24s-10.56,23.51-22,24l.84,0c11.8,0,22.89-10.76,22.89-24S47.73,47.7,35.93,47.7Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M13.38,71.82c0-13.08,11.75-23.72,23.39-24h-.51c-11.8,0-23.9,10.76-23.9,24s12.1,24,23.9,24h.51C25.13,95.53,13.38,84.9,13.38,71.82Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="16.66" y="73.47" width="15.26" height="4.97" fill="#6c63ff" />
    <path
      d="M27.54,49.54s-18.13,20.72,0,44.56l6,0S17,74.38,32.77,48l-3,.67Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M43.3,49.22s18.13,21,0,44.87l-6,0s16.52-19.86.76-46.2l3,.59Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M477,58.82C477,72.74,465.34,84,453,84s-25.07-11.29-25.07-25.21S440.57,33.61,453,33.61,477,44.89,477,58.82Z"
      transform="translate(-10.88 -19)"
      fill="url(#13b49d86-3a6c-4373-8a2b-23927da3a66d)"
    />
    <path
      d="M475.82,58.82c0,13.27-11.09,24-22.89,24S429,72.09,429,58.82s12.1-24,23.9-24S475.82,45.55,475.82,58.82Z"
      transform="translate(-10.88 -19)"
      fill="#f55f44"
    />
    <rect
      x="433.75"
      y="76.68"
      width="15.01"
      height="12.96"
      fill="url(#e13d1787-a849-404d-9971-b1babf765573)"
    />
    <rect x="434.2" y="76.68" width="14.22" height="12.29" fill="#e6e6e6" />
    <line
      x1="448.24"
      y1="76.68"
      x2="448.84"
      y2="65.44"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="434.55"
      y1="76.68"
      x2="433.75"
      y2="65.44"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M448.44,96.74a.83.83,0,0,0,0,.27.38.38,0,0,0,0,.1A.56.56,0,0,0,448.44,96.74Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M448.1,95.5a1.63,1.63,0,0,0,0,.44A1.67,1.67,0,0,0,448.1,95.5Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M450.67,96.15s0-.06,0-.1v0S450.67,96.1,450.67,96.15Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="438.38" y="64.69" width="5.31" height="1.85" fill="#f55f44" />
    <g opacity="0.2">
      <path
        d="M452.93,34.7l-.84,0c11.48.5,22,11.05,22,24s-10.56,23.51-22,24l.84,0c11.8,0,22.89-10.76,22.89-24S464.73,34.7,452.93,34.7Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M430.38,58.82c0-13.08,11.75-23.72,23.39-24h-.51c-11.8,0-23.9,10.76-23.9,24s12.1,24,23.9,24h.51C442.13,82.53,430.38,71.9,430.38,58.82Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="433.66" y="60.47" width="15.26" height="4.97" fill="#f55f44" />
    <path
      d="M444.54,36.54s-18.13,20.72,0,44.56l6,0S434,61.38,449.77,35l-3,.67Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M460.3,36.22s18.13,21,0,44.87l-6,0s16.52-19.86.76-46.2l3,.59Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M139.25,250.19c0,20.66-17.26,37.41-35.64,37.41s-37.22-16.75-37.22-37.41,18.84-37.41,37.22-37.41S139.25,229.53,139.25,250.19Z"
      transform="translate(-10.88 -19)"
      fill="url(#8d0e9b9d-b502-4368-bee2-132b20c20719)"
    />
    <path
      d="M137.54,250.19c0,19.7-16.45,35.66-34,35.66s-35.47-16-35.47-35.66,18-35.66,35.47-35.66S137.54,230.49,137.54,250.19Z"
      transform="translate(-10.88 -19)"
      fill="#f55f44"
    />
    <rect
      x="80.38"
      y="285.9"
      width="22.27"
      height="19.24"
      fill="url(#2bbe2ca3-b086-4129-9122-2e4a5aa8bf64)"
    />
    <rect x="81.04" y="285.9" width="21.11" height="18.24" fill="#e6e6e6" />
    <line
      x1="101.88"
      y1="285.9"
      x2="102.76"
      y2="269.23"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="81.56"
      y1="285.9"
      x2="80.37"
      y2="269.23"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M96.9,306.47a1.23,1.23,0,0,0,0,.4.57.57,0,0,0,0,.14A.83.83,0,0,0,96.9,306.47Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M96.4,304.64a2.42,2.42,0,0,0,0,.66A2.48,2.48,0,0,0,96.4,304.64Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M100.22,305.6s0-.1,0-.14v-.07C100.22,305.46,100.22,305.53,100.22,305.6Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="87.25" y="268.1" width="7.88" height="2.75" fill="#f55f44" />
    <g opacity="0.2">
      <path
        d="M103.57,214.4l-1.25,0c17,.74,32.72,16.41,32.72,35.63S119.36,285,102.32,285.7l1.25,0c17.52,0,34-16,34-35.66S121.09,214.4,103.57,214.4Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M70.1,250.19c0-19.41,17.45-35.2,34.72-35.65h-.75c-17.52,0-35.47,16-35.47,35.66s18,35.66,35.47,35.66h.75C87.54,285.39,70.1,269.6,70.1,250.19Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="80.24" y="261.85" width="22.65" height="7.38" fill="#f55f44" />
    <path
      d="M91.12,217.12s-26.9,30.75,0,66.13l8.88,0S75.48,254,98.88,214.9l-4.38,1Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M114.52,216.66s26.9,31.22,0,66.6l-8.88,0s24.53-29.48,1.13-68.57l4.38.88Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1034.14,54.25c0,19.47-16.26,35.25-33.58,35.25S965.5,73.71,965.5,54.25,983.25,19,1000.56,19,1034.14,34.78,1034.14,54.25Z"
      transform="translate(-10.88 -19)"
      fill="url(#6be94b36-c083-4e0b-93be-f3da3b19fa1e)"
    />
    <path
      d="M1032.53,54.25c0,18.55-15.5,33.6-32,33.6s-33.42-15-33.42-33.6,16.92-33.6,33.42-33.6S1032.53,35.69,1032.53,54.25Z"
      transform="translate(-10.88 -19)"
      fill="#4d8af0"
    />
    <rect
      x="978.05"
      y="86.79"
      width="20.98"
      height="18.13"
      fill="url(#cd7958c8-0c10-490b-9e34-7b85f0d28caa)"
    />
    <rect x="978.67" y="86.79" width="19.89" height="17.19" fill="#e6e6e6" />
    <line
      x1="998.31"
      y1="86.79"
      x2="999.14"
      y2="71.08"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="979.16"
      y1="86.79"
      x2="978.04"
      y2="71.08"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M994.25,107.27a1.16,1.16,0,0,0,0,.38.54.54,0,0,0,0,.13A.78.78,0,0,0,994.25,107.27Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M993.77,105.55a2.28,2.28,0,0,0,0,.62A2.33,2.33,0,0,0,993.77,105.55Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M997.37,106.45s0-.09,0-.13v-.07C997.37,106.32,997.37,106.38,997.37,106.45Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="984.52" y="70.02" width="7.43" height="2.59" fill="#ffcc80" />
    <g opacity="0.2">
      <path
        d="M1000.53,20.53l-1.18,0c16.06.69,30.83,15.46,30.83,33.57S1015.4,87,999.35,87.7l1.18,0c16.5,0,32-15,32-33.6S1017,20.53,1000.53,20.53Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M969,54.25c0-18.29,16.44-33.17,32.71-33.59H1001c-16.5,0-33.42,15-33.42,33.6s16.92,33.6,33.42,33.6h.71C985.43,87.41,969,72.54,969,54.25Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="977.92" y="64.13" width="21.34" height="6.96" fill="#ffcc80" />
    <path
      d="M988.8,23.1s-25.34,29,0,62.3l8.37,0S974.06,57.83,996.1,21l-4.13.94Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1010.84,22.65s25.34,29.41,0,62.74l-8.37,0s23.11-27.77,1.06-64.6l4.13.83Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1173.25,61.19c0,20.66-17.26,37.41-35.64,37.41s-37.22-16.75-37.22-37.41,18.84-37.41,37.22-37.41S1173.25,40.53,1173.25,61.19Z"
      transform="translate(-10.88 -19)"
      fill="url(#de1c6a66-0b91-463d-a557-a0141dbc776a)"
    />
    <path
      d="M1171.54,61.19c0,19.7-16.45,35.66-34,35.66s-35.47-16-35.47-35.66,18-35.66,35.47-35.66S1171.54,41.49,1171.54,61.19Z"
      transform="translate(-10.88 -19)"
      fill="#6c63ff"
    />
    <rect
      x="1114.38"
      y="96.9"
      width="22.27"
      height="19.24"
      fill="url(#1fff592f-6693-4eb3-9171-bb32ff9687db)"
    />
    <rect x="1115.04" y="96.9" width="21.11" height="18.24" fill="#6c63ff" />
    <line
      x1="1135.88"
      y1="96.9"
      x2="1136.76"
      y2="80.23"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="1115.56"
      y1="96.9"
      x2="1114.37"
      y2="80.23"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M1130.9,117.47a1.23,1.23,0,0,0,0,.4.57.57,0,0,0,0,.14A.83.83,0,0,0,1130.9,117.47Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M1130.4,115.64a2.42,2.42,0,0,0,0,.66A2.48,2.48,0,0,0,1130.4,115.64Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M1134.22,116.6s0-.1,0-.14v-.07C1134.22,116.46,1134.22,116.53,1134.22,116.6Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="1121.25" y="79.1" width="7.88" height="2.75" fill="#6c63ff" />
    <g opacity="0.2">
      <path
        d="M1137.57,25.4l-1.25,0c17,.74,32.72,16.41,32.72,35.63S1153.36,96,1136.32,96.7l1.25,0c17.52,0,34-16,34-35.66S1155.09,25.4,1137.57,25.4Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M1104.1,61.19c0-19.41,17.45-35.2,34.72-35.65h-.75c-17.52,0-35.47,16-35.47,35.66s18,35.66,35.47,35.66h.75C1121.54,96.39,1104.1,80.6,1104.1,61.19Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="1114.24" y="72.85" width="22.65" height="7.38" fill="#6c63ff" />
    <path
      d="M1125.12,28.12s-26.9,30.75,0,66.13l8.88,0S1109.48,65,1132.88,25.9l-4.38,1Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1148.52,27.66s26.9,31.22,0,66.6l-8.88,0s24.53-29.48,1.13-68.57l4.38.88Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1104.18,252.32c0,18.4-15.79,33.32-32.59,33.32s-34-14.92-34-33.32,17.23-33.32,34-33.32S1104.18,233.92,1104.18,252.32Z"
      transform="translate(-10.88 -19)"
      fill="url(#4e6c8e0b-171b-414f-b772-a9b03415ed1d)"
    />
    <path
      d="M1102.62,252.32c0,17.54-15,31.76-31.07,31.76s-32.44-14.22-32.44-31.76,16.42-31.76,32.44-31.76S1102.62,234.78,1102.62,252.32Z"
      transform="translate(-10.88 -19)"
      fill="#f55f44"
    />
    <rect
      x="1049.41"
      y="282.05"
      width="20.37"
      height="17.14"
      fill="url(#9eb5e881-07ee-4ce5-baa7-4927dd5ad0d0)"
    />
    <rect x="1050.01" y="282.05" width="19.3" height="16.25" fill="#e6e6e6" />
    <line
      x1="1069.08"
      y1="282.05"
      x2="1069.88"
      y2="267.2"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="1050.49"
      y1="282.05"
      x2="1049.4"
      y2="267.2"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M1065.46,302.45a1.07,1.07,0,0,0,0,.36.5.5,0,0,0,0,.13A.72.72,0,0,0,1065.46,302.45Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M1065,300.82a2.1,2.1,0,0,0,0,.59A2.15,2.15,0,0,0,1065,300.82Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M1068.49,301.68s0-.08,0-.13v-.06C1068.49,301.55,1068.49,301.61,1068.49,301.68Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="1055.7" y="266.2" width="7.21" height="2.45" fill="#f55f44" />
    <g opacity="0.2">
      <path
        d="M1071.55,220.45l-1.15,0c15.59.66,29.92,14.61,29.92,31.74s-14.34,31.08-29.92,31.74l1.15,0c16,0,31.07-14.22,31.07-31.76S1087.57,220.45,1071.55,220.45Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M1040.94,252.32c0-17.29,16-31.35,31.75-31.75H1072c-16,0-32.44,14.22-32.44,31.76S1056,284.09,1072,284.09h.69C1056.9,283.68,1040.94,269.61,1040.94,252.32Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="1049.29" y="260.63" width="20.71" height="6.58" fill="#f55f44" />
    <path
      d="M1060.17,222.87s-24.6,27.39,0,58.9l8.12,0s-22.43-26-1-60.85l-4,.89Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1081.56,222.45s24.6,27.81,0,59.32l-8.12,0s22.43-26.26,1-61.07l4,.78Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1176.09,455.8c0,20.33-17,36.8-35.06,36.8s-36.61-16.48-36.61-36.8S1122.95,419,1141,419,1176.09,435.48,1176.09,455.8Z"
      transform="translate(-10.88 -19)"
      fill="url(#b47c5690-0072-4895-bfce-7e12bed9c37f)"
    />
    <path
      d="M1174.41,455.8c0,19.37-16.19,35.08-33.42,35.08s-34.9-15.71-34.9-35.08,17.66-35.08,34.9-35.08S1174.41,436.43,1174.41,455.8Z"
      transform="translate(-10.88 -19)"
      fill="#47e6b1"
    />
    <rect
      x="1118"
      y="490.62"
      width="21.91"
      height="18.93"
      fill="url(#9c3c48fe-271f-491e-9bd0-9e0c46ee695c)"
    />
    <rect x="1118.65" y="490.62" width="20.76" height="17.94" fill="#e6e6e6" />
    <line
      x1="1139.16"
      y1="490.62"
      x2="1140.02"
      y2="474.22"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="1119.16"
      y1="490.62"
      x2="1117.99"
      y2="474.22"
      fill="none"
      stroke="#ad7676"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <g opacity="0.2">
      <path
        d="M1134.43,511.17a1.21,1.21,0,0,0,0,.39.56.56,0,0,0,0,.14A.81.81,0,0,0,1134.43,511.17Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M1133.94,509.37a2.38,2.38,0,0,0,0,.65A2.44,2.44,0,0,0,1133.94,509.37Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
      <path
        d="M1137.7,510.31s0-.09,0-.14v-.07C1137.7,510.17,1137.7,510.24,1137.7,510.31Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="1124.76" y="473.12" width="7.75" height="2.71" fill="#47e6b1" />
    <g opacity="0.2">
      <path
        d="M1141,420.6l-1.23,0c16.77.72,32.19,16.14,32.19,35.05s-15.42,34.33-32.19,35.05l1.23,0c17.23,0,33.42-15.71,33.42-35.08S1158.22,420.6,1141,420.6Z"
        transform="translate(-10.88 -19)"
      />
    </g>
    <g opacity="0.2">
      <path
        d="M1108.06,455.8c0-19.1,17.16-34.63,34.16-35.07h-.74c-17.23,0-34.9,15.71-34.9,35.08s17.66,35.08,34.9,35.08h.74C1125.22,490.44,1108.06,474.9,1108.06,455.8Z"
        transform="translate(-10.88 -19)"
        fill="#fff"
      />
    </g>
    <rect x="1117.86" y="466.96" width="22.28" height="7.26" fill="#47e6b1" />
    <path
      d="M1128.74,423.28s-26.46,30.25,0,65.05l8.74,0s-24.13-28.75-1.11-67.21l-4.31,1Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
    <path
      d="M1151.76,422.82s26.46,30.71,0,65.52l-8.74,0s24.13-29,1.11-67.45l4.31.86Z"
      transform="translate(-10.88 -19)"
      fill="#fff"
      opacity="0.2"
    />
  </svg>
);

export default AirBalloonFloating;
