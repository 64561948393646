import React, { Component } from "react";
import { Field } from "formik";
import classNames from "classnames";
import InputFeedback from "./InputFeedback";

const RadioButtonPlaceholder = ({ checked }) => (
  <label
    className={classNames(
      "radio-button",
      "radio-button--placeholder",
      "disabled",
      {
        checked: !!checked
      }
    )}
  >
    <input type="radio" checked={checked} disabled />
    <span style={{ width: `${Math.random() * 60 + 18}%` }} />
  </label>
);

class RadioButton extends Component {
  handleChange = event => {
    this.props.fetchDependent(event.target.value);
    this.props.field.onChange(event);
  };

  render() {
    const {
      field: { name, onBlur },
      id,
      label,
      value,
      checked,
      className
    } = this.props;

    return (
      <label
        htmlFor={id}
        className={classNames(
          "radio-button",
          { checked: !!checked },
          className
        )}
      >
        <input
          name={name}
          id={id}
          className={className}
          type="radio"
          value={value}
          checked={checked}
          onChange={this.handleChange}
          onBlur={onBlur}
        />
        {label}
      </label>
    );
  }
}

const RadioButtonGroup = ({
  error,
  touched,
  id,
  name,
  value,
  label,
  className,
  options,
  isLoadingOptions,
  fetchDependent
}) => {
  const classes = classNames(
    "input-group",
    {
      success: value || (!error && touched), // handle prefilled or user-filled
      error: !!error && touched
    },
    className
  );

  return (
    <div className={classes}>
      <fieldset>
        {!!label && <legend>{label}</legend>}
        {isLoadingOptions
          ? Array(Math.random() > 0.5 ? 2 : 4)
              .fill("")
              .map((_, i) => (
                <RadioButtonPlaceholder key={i} checked={Math.random() > 0.5} />
              ))
          : options.map((option, i) => (
              <Field
                key={i}
                component={RadioButton}
                name={name}
                id={`${name}-${option.value}`}
                label={option.label}
                value={option.value}
                checked={value === option.value}
                fetchDependent={fetchDependent}
              />
            ))}
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </div>
  );
};

export default RadioButtonGroup;
