import React from "react";

const defaultStyle = {
  fill: "#6C63FF",
  stroke: "none",
  maxWidth: 52,
  margin: "0 auto 36px"
};

const Logo = ({ style }) => (
  <svg viewBox="0 0 40 40" style={{ ...defaultStyle, ...style }}>
    <g>
      <path
        d="M44.2,42.3H35.5a20,20,0,1,0-10,2.7H44.2a1.26,1.26,0,0,0,1.3-1.3A1.5,1.5,0,0,0,44.2,42.3ZM8.2,25A17.3,17.3,0,1,1,25.5,42.3,17.24,17.24,0,0,1,8.2,25Z"
        transform="translate(-5.5 -5)"
      />
      <circle cx="20" cy="20" r="1.3" />
      <path
        d="M25.5,21a5.3,5.3,0,1,1,5.3-5.3A5.34,5.34,0,0,1,25.5,21Zm0-8a2.7,2.7,0,1,0,2.7,2.7A2.69,2.69,0,0,0,25.5,13Z"
        transform="translate(-5.5 -5)"
      />
      <path
        d="M25.5,39.7a5.3,5.3,0,1,1,5.3-5.3A5.34,5.34,0,0,1,25.5,39.7Zm0-8a2.69,2.69,0,0,0-2.7,2.7A2.61,2.61,0,0,0,25.5,37a2.69,2.69,0,0,0,2.7-2.7A2.61,2.61,0,0,0,25.5,31.7Z"
        transform="translate(-5.5 -5)"
      />
      <path
        d="M34.8,30.3A5.3,5.3,0,1,1,40.1,25,5.27,5.27,0,0,1,34.8,30.3Zm0-8A2.7,2.7,0,1,0,37.5,25,2.69,2.69,0,0,0,34.8,22.3Z"
        transform="translate(-5.5 -5)"
      />
      <path
        d="M16.2,30.3A5.3,5.3,0,1,1,21.5,25,5.34,5.34,0,0,1,16.2,30.3Zm0-8A2.7,2.7,0,1,0,18.9,25,2.75,2.75,0,0,0,16.2,22.3Z"
        transform="translate(-5.5 -5)"
      />
    </g>
  </svg>
);

export default Logo;
