import React from "react";
import { svgStyles } from "./styles";

const Fishing = _ => (
  <svg id="fishing-illustration" viewBox="0 0 1064.62 798.95" style={svgStyles}>
    <defs>
      <linearGradient
        id="9ef7ef10-53c7-4b47-a18d-df8c769a82a9"
        x1="552.67"
        y1="633.88"
        x2="552.67"
        y2="359.93"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="gray" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
        <stop offset="1" stopColor="gray" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="f123eb9f-f47a-40a3-adfc-57afba59094e"
        x1="567.66"
        y1="564.9"
        x2="567.66"
        y2="200"
        xlinkHref="#9ef7ef10-53c7-4b47-a18d-df8c769a82a9"
      />
    </defs>
    <title>fishing</title>
    <path
      d="M109.78,236.54c-21.29,36.26-6.93,81.52-3.44,123.28a226.69,226.69,0,0,1-6.57,76C90.59,471,73,504,68.5,540s9.4,78.42,43.93,90.94c8.57,3.11,17.84,4.16,26.18,7.84,22.62,10,32.65,36.76,33.42,61.18s-5.31,48.71-4.21,73.12c.62,13.74,3.89,28.09,13.34,38.2,8.69,9.29,21.33,13.74,33.5,17.7,38.48,12.53,78.66,23.61,119,19.74s81.23-25.69,96.73-62.6c5.61-13.36,7.69-28,13.33-41.32s16.32-26.11,30.84-28.2c8.48-1.22,17,1.36,25.18,3.9L617,757c18,5.61,38.2,11.09,55.1,2.78,8.69-4.28,15.28-11.71,21.62-19,50.1-57.25,102.3-119.34,111.47-194.41,3.23-26.45.86-53.36,4.45-79.76s14.58-53.75,37.39-68c17.59-11,39.36-12.65,60.08-14.87,33.54-3.6,67.29-9.7,97.71-24.07s57.49-37.82,70.44-68.52c3.83-9.08,6.4-19.13,4.24-28.72-3.86-17.11-21.07-27.59-37.2-35-48.24-22-100.56-33.78-152.67-44.15-23.9-4.76-48-9.27-72.42-9-21.11.22-42,4-62.79,7.59C576.38,212.48,384,231.81,205.31,194.49,167.84,186.66,130.19,201.77,109.78,236.54Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
      opacity="0.1"
    />
    <path
      d="M364.47,166.32s215.24-68.53,299.83-18.2c58.18,34.61,189.28,24.66,265.49,15.06a271.53,271.53,0,0,1,125,13.58c38.57,13.74,79.12,30.33,77.42,38.82C1129,231.64,364.47,222,364.47,222Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
      opacity="0.4"
    />
    <path
      d="M873.12,477.77l-.8.07.25-.62-1,.41,1-2.63s-3.25,1.31-9.27,3.45C715.49,485.34,406.2,359.93,406.2,359.93L235,383.44a36.06,36.06,0,0,1-2.82-2.95l.76,3.09q-.4-.44-.76-.87l.27,1.08-.27,0,.42.58,19.59,79.46S601.31,656.19,811.42,631.74l59.81-151.16Z"
      transform="translate(-67.69 -50.53)"
      fill="url(#9ef7ef10-53c7-4b47-a18d-df8c769a82a9)"
    />
    <path
      d="M411.59,365S723.2,491.32,861.34,478.47L769.25,615.54l-333-103.87L273.45,437.78l76-51.4Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
    />
    <path
      d="M411.59,365S723.2,491.32,861.34,478.47L769.25,615.54l-333-103.87L273.45,437.78l76-51.4Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <polygon
      points="331.58 352.45 414.57 371.19 245.38 401.17 205.76 365.83 331.58 352.45"
      fill="#fff"
      opacity="0.1"
    />
    <polygon
      points="368.52 429.01 466.51 393.14 535.58 416.16 414.57 461.14 368.52 429.01"
      fill="#fff"
      opacity="0.1"
    />
    <polygon
      points="503.45 469.7 611.07 440.26 706.91 466.49 605.18 500.76 503.45 469.7"
      fill="#fff"
      opacity="0.1"
    />
    <polygon
      points="176.31 337.46 343.9 314.44 343.9 355.13 203.62 375.47 176.31 337.46"
      fill="#6c63ff"
    />
    <polygon
      points="177.38 339.6 343.9 314.44 343.9 355.13 203.62 375.47 177.38 339.6"
      fill="#fff"
      opacity="0.1"
    />
    <path
      d="M95.69,254.13a72.91,72.91,0,0,1,56-70.88c69.73-16.73,186.67-37,243.87-3,84.6,50.33,323.39,6.42,323.39,6.42s147.77,45,144.56,61-767.79,6.42-767.79,6.42Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
    />
    <path
      d="M686.42,197.1c1.84-1.71,3.57-3.73,4.1-6.19s-.52-5.41-2.87-6.31c-2.63-1-5.45.82-7.58,2.66s-4.58,3.95-7.37,3.56a11.23,11.23,0,0,0,3.47-10.51,4.39,4.39,0,0,0-1-2.13c-1.46-1.56-4.11-.89-5.86.34-5.57,3.92-7.12,11.48-7.15,18.29-.56-2.46-.09-5-.1-7.53s-.7-5.31-2.83-6.66a8.52,8.52,0,0,0-4.31-1c-2.5-.09-5.29.16-7,2-2.12,2.27-1.57,6.09.27,8.6s4.66,4.07,7.25,5.8a16.07,16.07,0,0,1,5.18,4.94,4.89,4.89,0,0,1,.38.88h15.69A43.73,43.73,0,0,0,686.42,197.1Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
    />
    <path
      d="M341.62,162.83c1.84-1.71,3.57-3.73,4.1-6.19s-.52-5.41-2.87-6.31c-2.63-1-5.45.82-7.58,2.66s-4.58,3.95-7.37,3.56A11.23,11.23,0,0,0,331.37,146a4.39,4.39,0,0,0-1-2.13c-1.46-1.56-4.11-.89-5.86.34-5.57,3.92-7.12,11.48-7.15,18.29-.56-2.46-.09-5-.1-7.53s-.7-5.31-2.83-6.66a8.52,8.52,0,0,0-4.31-1c-2.5-.09-5.29.16-7,2-2.12,2.27-1.57,6.09.27,8.6s4.66,4.07,7.25,5.8a16.07,16.07,0,0,1,5.18,4.94,4.89,4.89,0,0,1,.38.88h15.69A43.73,43.73,0,0,0,341.62,162.83Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
    />
    <path
      d="M853.47,236.72c1.84-1.71,3.57-3.73,4.1-6.19s-.52-5.41-2.87-6.31c-2.63-1-5.45.82-7.58,2.66s-4.58,3.95-7.37,3.56a11.23,11.23,0,0,0,3.47-10.51,4.39,4.39,0,0,0-1-2.13c-1.46-1.56-4.11-.89-5.86.34-5.57,3.92-7.12,11.48-7.15,18.29-.56-2.46-.09-5-.1-7.53s-.7-5.31-2.83-6.66a8.52,8.52,0,0,0-4.31-1c-2.5-.09-5.29.16-7,2-2.12,2.27-1.57,6.09.27,8.6s4.66,4.07,7.25,5.8a16.07,16.07,0,0,1,5.18,4.94,4.89,4.89,0,0,1,.38.88h15.69A43.73,43.73,0,0,0,853.47,236.72Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
    />
    <path
      d="M656.68,558c-10-9.87-17.91-21.59-22.09-34.95-3.21-10.29-6.08-20.34-6.08-23.59,0-5.69-7.91-27.1-17.68-37.42-.34-.36-.69-.71-1-1-1.7-6.72-5.09-23.67.79-34.19a48.63,48.63,0,0,0,3.59-8.05,95.56,95.56,0,0,1,16-29.75,35.58,35.58,0,0,0,3.74-5.49c2.45.42,3.89-.26,3.59-2.67,0-.09,0-.18,0-.27,1.13-.26,1.72-1.08,1.52-2.69-1.49-11.86,4.48-48.93,4.48-48.93A142.15,142.15,0,0,0,650.92,277c-1.49-28.17-31.36-34.1-31.36-34.1l-2.47,2.45c-2-1.23-4.24-2.61-6.51-4.07a3.75,3.75,0,0,0,2-2.07c2.59-5.37,2.72-12.73,3.09-18.55a17.52,17.52,0,0,0-.26-5.63,13.07,13.07,0,0,0-2.28-4.35,26.05,26.05,0,0,0-19.57-10.27c-2.48-.1-5,.16-7.43.16-4.56,0-9.12-.88-13.66-.5s-9.34,2.38-11.35,6.45c-1.26,2.55-1.27,5.62-2.76,8-1.72,2.79-5.07,4.21-7,6.86a8.56,8.56,0,0,0-1.63,5,3.47,3.47,0,0,0,.35,1.69,6.69,6.69,0,0,0,1.67,1.62,6.55,6.55,0,0,1,1.28,8.1,8,8,0,0,1,6.34,6.13,2.53,2.53,0,0,0,.54,1.41,1.91,1.91,0,0,0,1,.42c2.91.5,5.85-.87,8.05-2.84.18.33.35.65.55,1a21,21,0,0,0,26.14,8.4q.62.78,1.22,1.61a33.84,33.84,0,0,1,4.06,7.43l-2.3,2.28a89.89,89.89,0,0,0-5.45,9.86,87.68,87.68,0,0,0-8.12,22.56c-5.42,5.36-13.8,15-14.8,23.92,0,0-38.83,19.28-49.28,8.9s-23.89,7.41-20.91,10.38,11.95,8.9,22.4,3H564.3a47.36,47.36,0,0,0,18-6.27c-.74,2-1.66,4.07-2.68,6.18-1.57,3-3.26,6-4.84,8.69-.87.71-1.73,1.4-2.59,2.06-5.35,2.21-12.08,5-19.07,8a30.1,30.1,0,0,0-10.76,4.62c-16.14,7-30.75,13.82-30.32,15.24-1.08.71-1.64,1.24-1.49,1.52s.28.62.49,1.14c-1.15,4.23-1.37,12.88,1,30,6,43-7.47,50.41-7.47,50.41s-10.45,13.34-22.4,11.86-1.49,10.38,9,8.9c7.05-1,22.27-2,31.41-2.55a5.61,5.61,0,0,0,5.09-7,45,45,0,0,1-.66-20.07c2.25-11.16,7.88-32.4,9.89-44.13l0-.13c3-3,8.59-7.13,15.48-6.15,3.59.51,10-2.84,16.88-7.44-3.6,20.49-6.35,42.5-3.44,50.44,6,16.31,11.95,23.72,11.95,23.72s2.87.13,6.9.08l1.93,3,27,41.44s23.89,34.1,1.49,38.55c0,0-17.92,4.45-10.45,10.38,0,0,52.27,1.48,53.76-3C658.76,560.59,658,559.3,656.68,558Z"
      transform="translate(-67.69 -50.53)"
      fill="url(#f123eb9f-f47a-40a3-adfc-57afba59094e)"
    />
    <path
      d="M623.9,251.8s-20.32,40.64-21.77,20.32a32.48,32.48,0,0,0-6.07-16.76,40.13,40.13,0,0,0-14.25-12.26s11.61-21.77,10.16-16c-.74,3,7.61,9.35,16,14.88C615.92,247.29,623.9,251.8,623.9,251.8Z"
      transform="translate(-67.69 -50.53)"
      fill="#ffb9b9"
    />
    <path
      d="M528.11,452.1a44.35,44.35,0,0,0,.64,19.65,5.48,5.48,0,0,1-4.94,6.89c-8.89.53-23.68,1.51-30.53,2.49-10.16,1.45-20.32-10.16-8.71-8.71s21.77-11.61,21.77-11.61,13.06-7.26,7.26-49.35c-2.31-16.75-2.09-25.23-1-29.36a6.61,6.61,0,0,1,1.35-2.86s0,0,0,0a2.21,2.21,0,0,1,1.16-.7,3.47,3.47,0,0,1,2.79,1s18.87,17.42,20.32,21.77c.36,1.07.1,3.85-.55,7.61C535.77,420.39,530.3,441.17,528.11,452.1Z"
      transform="translate(-67.69 -50.53)"
      fill="#ffb9b9"
    />
    <path
      d="M528.11,452.1a44.35,44.35,0,0,0,.64,19.65,5.48,5.48,0,0,1-4.94,6.89c-8.89.53-23.68,1.51-30.53,2.49-10.16,1.45-20.32-10.16-8.71-8.71s21.77-11.61,21.77-11.61,13.06-7.26,7.26-49.35c-2.31-16.75-2.09-25.23-1-29.36a6.61,6.61,0,0,1,1.35-2.86s0,0,0,0a2.21,2.21,0,0,1,1.16-.7,3.47,3.47,0,0,1,2.79,1s18.87,17.42,20.32,21.77c.36,1.07.1,3.85-.55,7.61C535.77,420.39,530.3,441.17,528.11,452.1Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.05"
    />
    <path
      d="M655.84,556.61c-1.45,4.35-52.25,2.9-52.25,2.9-7.26-5.81,10.16-10.16,10.16-10.16,21.77-4.35-1.45-37.74-1.45-37.74L586,471l-5.67-8.78s7.26-10.16,20.32-8.71c3,.33,6.07,2.39,8.94,5.46,9.49,10.1,17.19,31.06,17.19,36.63,0,3.18,2.79,13,5.91,23.09,4.06,13.08,11.79,24.54,21.47,34.21C655.47,554.25,656.2,555.52,655.84,556.61Z"
      transform="translate(-67.69 -50.53)"
      fill="#ffb9b9"
    />
    <path
      d="M537.72,408.91a25.37,25.37,0,0,0-3.8,4c-10.7-1.34-18.93-24.85-21.29-30.81-.2-.51-.36-.89-.48-1.12s.51-.91,1.83-1.74c0,0,0,0,0,0l1.16-.7a3.47,3.47,0,0,1,2.79,1s18.87,17.42,20.32,21.77C538.63,402.38,538.37,405.15,537.72,408.91Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M583.26,347.6s-71.12,29-69.67,31.93,10.16,30.48,21.77,31.93c0,0,7.26-10.16,17.42-8.71s43.54-29,43.54-29l23.22-17.42Z"
      transform="translate(-67.69 -50.53)"
      fill="#4c4c78"
    />
    <path
      d="M583.26,347.6s-71.12,29-69.67,31.93,10.16,30.48,21.77,31.93c0,0,7.26-10.16,17.42-8.71s43.54-29,43.54-29l23.22-17.42Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.05"
    />
    <path
      d="M610.84,463.71c0,6.05-15.4,7.2-24.81,7.33l-5.67-8.78s7.26-10.16,20.32-8.71c3,.33,6.07,2.39,8.94,5.46C610.28,461.9,610.84,463.71,610.84,463.71Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M577.46,354.86s-17.42,74-11.61,90,11.61,23.22,11.61,23.22,31.93,1.45,31.93-7.26c0,0-7.26-23.22,0-36.29a47.79,47.79,0,0,0,3.49-7.88,93.76,93.76,0,0,1,15.51-29.13c3.53-4.36,6.23-9.29,5.67-13.79C632.61,362.11,577.46,354.86,577.46,354.86Z"
      transform="translate(-67.69 -50.53)"
      fill="#4c4c78"
    />
    <path
      d="M589.07,292.44S571.65,307,570.2,320c0,0-37.74,18.87-47.9,8.71S499.08,336,502,338.89s11.61,8.71,21.77,2.9H564.4s27.58-2.9,29-24.67S589.07,292.44,589.07,292.44Z"
      transform="translate(-67.69 -50.53)"
      fill="#ffb9b9"
    />
    <path
      d="M589.07,292.44S571.65,307,570.2,320c0,0-37.74,18.87-47.9,8.71S499.08,336,502,338.89s11.61,8.71,21.77,2.9H564.4s27.58-2.9,29-24.67S589.07,292.44,589.07,292.44Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.05"
    />
    <path
      d="M604.31,240.92c-.26.94.39-.87,0,0-2.12,4.8-3.38,12.44-8.24,14.44a40.13,40.13,0,0,0-14.25-12.26s11.61-21.77,10.16-16C591.23,230.09,596,235.39,604.31,240.92Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M607.21,235.11a20.32,20.32,0,1,1-20.32-20.32A20.19,20.19,0,0,1,607.21,235.11Z"
      transform="translate(-67.69 -50.53)"
      fill="#ffb9b9"
    />
    <path
      d="M596.33,267.77l20.32-20.32s29,5.81,30.48,33.38a140.05,140.05,0,0,1-7.26,50.8s-5.81,36.29-4.35,47.9-37.74-17.42-37.74-17.42-31.93,5.81-30.48,1.45,18.87-29,16-37.74c-1.25-3.75-.89-12.06-.07-20.32a86.21,86.21,0,0,1,13.13-37.74Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M641.32,328.73S635.52,365,637,376.63s-37.74-17.42-37.74-17.42-5,.91-11,1.74c-9.07,1.26-20.36,2.34-19.49-.29a13,13,0,0,1,.83-1.79c3.79-7.16,17.77-28.09,15.14-36-1.25-3.74-.89-12.06-.07-20.32a86.3,86.3,0,0,1,13.14-37.74l20.32-20.32s29,5.81,30.48,33.38A140.05,140.05,0,0,1,641.32,328.73Z"
      transform="translate(-67.69 -50.53)"
      fill="#cbcdda"
    />
    <path
      d="M607.21,235.11a20.19,20.19,0,0,1-1.71,8.17,21.29,21.29,0,0,1-4.43-5,81.63,81.63,0,0,0-5.66-7.72,3.86,3.86,0,0,0-1.76-1.38c-1.34-.38-2.7.84-3.08,2.19a12.54,12.54,0,0,0,0,4.17,4.84,4.84,0,0,1-1.09,3.92c-1.73,1.61-4.53.49-6.5-.83s-4.44-2.79-6.53-1.68a6.08,6.08,0,0,0-1.92,1.93,49.56,49.56,0,0,1-5.07,6.68,20.32,20.32,0,1,1,37.74-10.45Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M614.31,222.78a17.26,17.26,0,0,0-.25-5.51,12.83,12.83,0,0,0-2.21-4.25,25.27,25.27,0,0,0-19-10.06c-2.41-.09-4.81.16-7.22.16-4.43,0-8.86-.86-13.28-.49s-9.08,2.33-11,6.31c-1.22,2.5-1.23,5.5-2.68,7.87-1.67,2.73-4.93,4.12-6.8,6.72a8.42,8.42,0,0,0-1.58,4.89,3.42,3.42,0,0,0,.34,1.65,6.53,6.53,0,0,0,1.62,1.58,6.44,6.44,0,0,1,1.24,7.93,7.76,7.76,0,0,1,6.16,6,2.49,2.49,0,0,0,.52,1.38,1.85,1.85,0,0,0,1,.41c2.91.51,5.87-.93,8-2.95s3.69-4.6,5.4-7a5.93,5.93,0,0,1,1.91-1.93c2.09-1.11,4.55.38,6.52,1.69s4.78,2.43,6.51.81a4.81,4.81,0,0,0,1.09-3.91,12.36,12.36,0,0,1,0-4.17c.38-1.35,1.73-2.57,3.08-2.19a3.79,3.79,0,0,1,1.74,1.39,81,81,0,0,1,5.67,7.72c1.88,2.91,7.5,9.8,10.24,4.09C613.83,235.68,614,228.47,614.31,222.78Z"
      transform="translate(-67.69 -50.53)"
      fill="#b96b6b"
    />
    <path
      d="M584,322.2s21.77-1.45,27.58,2.9,29,2.9,29,2.9"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M632.61,314.22s-7.26,14.51-7.26,18.87-5.81,10.16-5.81,10.16-16.4,9-31.31,17.71c-9.07,1.26-20.36,2.34-19.49-.29a13,13,0,0,1,.83-1.79c15.47-9.42,36.91-36,36.91-36,0-13.06,7.26-36.29,7.26-36.29s17.42-8.71,23.22,0S632.61,314.22,632.61,314.22Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.05"
    />
    <path
      d="M244,384.77l19.27,78.17S599.52,648.2,801.91,624.64l58.9-148.85s-145.16,58.68-299.2,31.7a153,153,0,0,1-17.48-4.17C498.1,489.54,274.71,421.24,244,384.77Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M244,386.91l19.27,78.17s336.24,185.25,538.63,161.7l58.9-148.85s-145.16,58.68-299.2,31.7a153,153,0,0,1-17.48-4.17C498.1,491.68,274.71,423.38,244,386.91Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
    />
    <rect
      x="460.85"
      y="153.18"
      width="7.5"
      height="278.65"
      transform="translate(-126.14 408.8) rotate(-49.88)"
      fill="#535461"
    />
    <path
      d="M610.84,283.74S603.58,307,603.58,320c0,0-30.48,37.74-45,39.19s-23.22,10.16-17.42,13.06,13.06,7.26,21.77,0,53.7-31.93,53.7-31.93,5.81-5.81,5.81-10.16,7.26-18.87,7.26-18.87,10.16-18.87,4.35-27.58S610.84,283.74,610.84,283.74Z"
      transform="translate(-67.69 -50.53)"
      fill="#ffb9b9"
    />
    <path
      d="M628.26,260.51s-26.13-2.9-21.77,34.83c0,0,17.42-10.16,31.93,0C638.42,295.35,644.22,267.77,628.26,260.51Z"
      transform="translate(-67.69 -50.53)"
      opacity="0.1"
    />
    <path
      d="M628.26,257.61s-26.13-2.9-21.77,34.83c0,0,17.42-10.16,31.93,0C638.42,292.44,644.22,264.87,628.26,257.61Z"
      transform="translate(-67.69 -50.53)"
      fill="#cbcdda"
    />
    <line
      x1="296.78"
      y1="158.86"
      x2="288.21"
      y2="589.1"
      fill="none"
      stroke="#535461"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M334.49,608.57s-32.12,19.27-11.78,62.11"
      transform="translate(-67.69 -50.53)"
      fill="none"
      stroke="#6c63ff"
      strokeMiterlimit="10"
      opacity="0.15"
    />
    <path
      d="M349.48,616.07s-32.12,19.27-11.78,62.11"
      transform="translate(-67.69 -50.53)"
      fill="none"
      stroke="#6c63ff"
      strokeMiterlimit="10"
      opacity="0.15"
    />
    <path
      d="M366.61,608.57s32.12,19.27,11.78,62.11"
      transform="translate(-67.69 -50.53)"
      fill="none"
      stroke="#6c63ff"
      strokeMiterlimit="10"
      opacity="0.15"
    />
    <path
      d="M354.83,619.28s32.12,19.27,11.78,62.11"
      transform="translate(-67.69 -50.53)"
      fill="none"
      stroke="#6c63ff"
      strokeMiterlimit="10"
      opacity="0.15"
    />
    <path
      d="M226.57,109H219.2a5.16,5.16,0,0,0,0-10.32H159.52a5.16,5.16,0,1,0,0,10.32h7.37a5.16,5.16,0,0,0,0,10.32H156.57a5.16,5.16,0,0,0,0,10.32h59.68a5.16,5.16,0,0,0,0-10.32h10.32a5.16,5.16,0,1,0,0-10.32Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
      opacity="0.1"
    />
    <path
      d="M634.55,60.84h-7.37a5.16,5.16,0,0,0,0-10.32H567.5a5.16,5.16,0,1,0,0,10.32h7.37a5.16,5.16,0,1,0,0,10.32H564.56a5.16,5.16,0,1,0,0,10.32h59.68a5.16,5.16,0,0,0,0-10.32h10.32a5.16,5.16,0,1,0,0-10.32Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
      opacity="0.1"
    />
    <path
      d="M848.72,140.08h-7.37a5.16,5.16,0,1,0,0-10.32H781.67a5.16,5.16,0,1,0,0,10.32H789a5.16,5.16,0,0,0,0,10.32H778.72a5.16,5.16,0,1,0,0,10.32h59.68a5.16,5.16,0,1,0,0-10.32h10.32a5.16,5.16,0,0,0,0-10.32Z"
      transform="translate(-67.69 -50.53)"
      fill="#6c63ff"
      opacity="0.1"
    />
  </svg>
);

export default Fishing;
