import React, { Component } from "react";
import { Field } from "formik";
import classNames from "classnames";
import InputFeedback from "./InputFeedback";

const CheckboxPlaceholder = ({ checked }) => (
  <label
    className={classNames("checkbox", "checkbox--placeholder", "disabled", {
      checked: !!checked
    })}
  >
    <input type="checkbox" checked={checked} disabled />
    <span style={{ width: `${Math.random() * 60 + 18}%` }} />
  </label>
);

const Checkbox = ({
  field: { name },
  id,
  label,
  value,
  checked,
  className,
  onChange,
  ...props
}) => {
  return (
    <label
      htmlFor={id}
      className={classNames("checkbox", { checked: !!checked }, className)}
    >
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

class CheckboxGroup extends Component {
  handleChange = event => {
    const targetValue = event.target.value;
    const currentValue = this.props.value;

    const nextValue = currentValue.includes(targetValue)
      ? currentValue.filter(v => v !== targetValue) // remove
      : currentValue.concat(targetValue); // add

    this.props.onChange(this.props.name, nextValue);

    if (!this.props.touched) {
      this.props.onBlur(this.props.name, true);
    }
  };

  render() {
    const {
      value,
      error,
      touched,
      label,
      name,
      className,
      options,
      isLoadingOptions,
      noOptionMessage
    } = this.props;

    const classes = classNames(
      "input-group",
      {
        success: value || (!error && touched), // handle prefilled or user-filled
        error: !!error && touched
      },
      className
    );

    return (
      <div className={classes}>
        <fieldset>
          {!!label && <legend>{label}</legend>}
          {isLoadingOptions ? (
            Array(4)
              .fill("")
              .map((_, i) => (
                <CheckboxPlaceholder key={i} checked={Math.random() > 0.5} />
              ))
          ) : options.length ? (
            options.map((option, i) => (
              <Field
                key={i}
                component={Checkbox}
                onChange={this.handleChange}
                name={name}
                id={`${name}-${option.value}`}
                label={option.label}
                value={option.value}
                checked={value.includes(option.value)}
              />
            ))
          ) : (
            <p className="no-option-message">{noOptionMessage}</p>
          )}
          {touched && <InputFeedback error={error} />}
        </fieldset>
      </div>
    );
  }
}

export default CheckboxGroup;
