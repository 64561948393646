import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ArrowBack, Ticket } from "./index";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NoContent from "./NoContent";
import "./Queue.scss";

import faker from "faker";

import smoothscroll from "smoothscroll-polyfill"; // for tickets
smoothscroll.polyfill();

const seedItems = number =>
  Array(number)
    .fill("")
    .map(i => ({
      id: faker.random.uuid(),
      name: faker.commerce.product(),
      variety: faker.commerce.productAdjective(),
      quantity: faker.random.number({ min: 1, max: 4 }),
      modifiers: Array(faker.random.number(3))
        .fill("")
        .map(mod => faker.commerce.productAdjective()),
      notes: faker.random.number(3) < 2 ? faker.lorem.lines(1) : ""
    }));

const seedTickets = number =>
  Array(number)
    .fill("")
    .map(i => ({
      id: faker.random.uuid(),
      name: `${faker.name.findName()} -- ${faker.random.number(80)}`,
      createdAt: new Date(Date.now() - faker.random.number((i + 1) * 1800)),
      completedAt: null,
      items: seedItems(faker.random.number({ min: 3, max: 7 }))
    }));

class Sample extends Component {
  state = {
    tickets: [],
    locationName: "Sample",
    name: "Queue"
  };

  seedDummyTickets = _ => {
    const dummyTickets = seedTickets(faker.random.number({ min: 6, max: 8 }));
    this.setState({ tickets: dummyTickets });
  };

  completeTicket = id => {
    this.setState({ tickets: this.state.tickets.filter(t => t.id !== id) });
  };

  renderComplete = _ => (
    <div className="no-content">
      <NoContent />
      <button onClick={this.seedDummyTickets}>Generate Dummy Data</button>
    </div>
  );

  renderTickets = tickets =>
    tickets.map((ticket, i) => (
      <CSSTransition
        key={ticket.id}
        timeout={1600}
        classNames="ticket-animation"
      >
        <Ticket {...ticket} completeTicket={this.completeTicket} />
      </CSSTransition>
    ));

  render() {
    const { locationName, name, tickets } = this.state;
    return (
      <div className="queue">
        <header className="queue__header">
          <Link to="/">
            <ArrowBack />
          </Link>
          <h2 className="queue__header__name">
            {locationName === "" && name === ""
              ? ""
              : `${locationName} ${name}`}
          </h2>
        </header>
        <main className="queue__main">
          <TransitionGroup className="ticket-list">
            {this.renderTickets(tickets)}
          </TransitionGroup>
          {!tickets.length && this.renderComplete()}
        </main>
      </div>
    );
  }
}

export default Sample;
