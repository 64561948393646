import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loader from "./Loader";
import NoContent from "./NoContent";
import "./Queue.scss";

import { db } from "../base";

import smoothscroll from "smoothscroll-polyfill"; // for tickets
smoothscroll.polyfill();

const isDefined = prop => {
  if (prop === null) return false;

  switch (typeof prop) {
    case "string":
      return prop && prop.length;
    case "number":
      return true;
    case "object":
      return Array.isArray(prop)
        ? prop.length > 0
        : Object.keys(prop).length > 0;
    default:
      return false;
  }
};

export const ArrowUp = props => <Arrow direction="up" {...props} />;
export const ArrowDown = props => <Arrow direction="down" {...props} />;
export const ArrowBack = props => <Arrow direction="back" {...props} />;

export const Arrow = ({ direction, onClick }) => (
  <svg
    className={`arrow-icon arrow-icon--${direction}`}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    onClick={onClick}
  >
    <g strokeWidth="1.5" strokeLinejoin="round" strokeMiterlimit="10">
      <circle className="arrow-icon__circle" cx="16" cy="16" r="15.12" />
      <path
        className="arrow-icon__arrow"
        d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"
      />
    </g>
  </svg>
);

export class Ticket extends Component {
  state = {
    canOverflow: false,
    isScrolled: false,
    scrollComplete: false,
    trackerOffset: 0
  };

  componentDidMount = () => {
    this.checkOverflow();
  };

  checkOverflow = _ => {
    if (!!this.itemList) {
      const clientHeight = this.itemList.clientHeight;
      const scrollTop = this.itemList.scrollTop;
      const scrollHeight = this.itemList.scrollHeight;
      const trackerOffset = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 240
      );

      this.setState({
        canOverflow: scrollHeight > clientHeight,
        isScrolled: scrollTop > 5,
        scrollComplete: scrollHeight <= clientHeight + scrollTop,
        trackerOffset
      });
    }
  };

  scrollToTop = _ => {
    this.itemList.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  scrollToBottom = _ => {
    this.itemList.scroll({
      top: this.itemList.scrollHeight,
      left: 0,
      behavior: "smooth"
    });
  };

  render() {
    const { name, items, id, completeTicket } = this.props;
    const customer = name.match(/([a-zA-Z])\w+/g).join(" ");
    const tableNumber = name.match(/\d+/g) ? name.match(/\d+/g).pop() : "?"; // get last digits from name

    return (
      <div className="ticket">
        {this.state.canOverflow && (
          <div
            className="scroll-tracker"
            style={{
              top: this.header.clientHeight,
              width: this.state.trackerOffset
            }}
          />
        )}
        <header className="ticket__header" ref={node => (this.header = node)}>
          <h3 className="ticket__header__name">{customer}</h3>
        </header>
        <section
          className="ticket__content"
          ref={node => (this.itemList = node)}
          onScroll={this.checkOverflow}
        >
          {this.state.canOverflow &&
            this.state.isScrolled && (
              <div
                className="up-marker"
                style={{
                  top: `${this.header.clientHeight + 8}px`,
                  left: `${(this.header.clientWidth - 24) / 2}px` // 24 for padding
                }}
              >
                <ArrowUp onClick={this.scrollToTop} />
              </div>
            )}
          <ul className="ticket__content__items">
            {items.map(({ name, variety, quantity, modifiers, notes }, i) => (
              <li className="item" key={i}>
                <h4 className="item__label">{name}</h4>
                {isDefined(variety) && (
                  <div className="item__detail item__detail--variety">
                    <p>{variety}</p>
                  </div>
                )}
                {isDefined(quantity) && (
                  <div className="item__detail item__detail--quantity">
                    <p>
                      <strong className="label">Quantity:&nbsp;</strong>
                      {quantity}
                    </p>
                  </div>
                )}
                {isDefined(modifiers) && (
                  <div className="item__detail item__detail--modifiers">
                    <p>
                      <strong className="label">
                        {modifiers.length > 1 ? "Modifiers:" : "Modifer:"}
                        &nbsp;
                      </strong>
                      {modifiers.join(", ")}
                    </p>
                  </div>
                )}
                {isDefined(notes) && (
                  <div className="item__detail item__detail--notes">
                    <p>
                      <strong className="label">Notes:&nbsp;</strong>
                      {notes}
                    </p>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {this.state.canOverflow &&
            !this.state.scrollComplete && (
              <div
                className="down-marker"
                style={{
                  bottom: `${this.footer.clientHeight + 8}px`,
                  left: `${(this.footer.clientWidth - 24) / 2}px` // 24 for padding
                }}
              >
                <ArrowDown onClick={this.scrollToBottom} />
              </div>
            )}
        </section>
        <footer className="ticket__footer" ref={node => (this.footer = node)}>
          <button
            className="complete-button"
            onClick={() => completeTicket(id)}
          >
            Complete
          </button>
          {!!tableNumber && <h4 className="table-number">{tableNumber}</h4>}
        </footer>
      </div>
    );
  }
}

class Queue extends Component {
  state = {
    isLoadingTickets: false,
    tickets: [],
    locationName: "",
    name: ""
  };

  componentDidMount() {
    this.fetchDetails();
    this.fetchTickets();
  }

  fetchDetails = _ => {
    db.collection("organizations")
      .doc(this.props.organizationId)
      .collection("queues")
      .doc(this.props.match.params.queueId)
      .get()
      .then(snapshot => {
        const { locationName, name } = snapshot.data();
        this.setState({ locationName, name });
      });
  };

  fetchTickets = () => {
    this.setState({ isLoadingTickets: true });

    db.collection("tickets")
      .where("queueId", "==", this.props.match.params.queueId)
      .where("completedAt", "==", null)
      .onSnapshot(snapshot => {
        if (this.state.isLoadingTickets) {
          this.setState({ isLoadingTickets: false });
        }

        snapshot.docChanges().forEach(change => {
          switch (change.type) {
            case "added":
              this.setState({
                tickets: [
                  ...this.state.tickets,
                  { id: change.doc.id, ...change.doc.data() }
                ]
              });
              break;
            case "removed":
              this.setState({
                tickets: this.state.tickets.filter(t => t.id !== change.doc.id)
              });
              break;
            case "modified":
              this.setState({
                tickets: this.state.tickets.map(
                  t =>
                    t.id === change.doc.id
                      ? { id: change.doc.id, ...change.doc.data() }
                      : t
                )
              });
              break;
            default:
              console.error("Unkown doc change", change);
          }
        });
      });
  };

  completeTicket = id => {
    const document = db.collection("tickets").doc(id);
    const completedAt = new Date();

    document.set({ completedAt }, { merge: true }).then(() =>
      db.runTransaction(transaction =>
        transaction.get(document).then(doc => {
          const data = doc.data();
          const timeToComplete = completedAt - data.createdAt.toDate();

          return transaction.update(document, { timeToComplete });
        })
      )
    );
  };

  renderLoading = _ => <Loader />;

  renderComplete = _ => (
    <div className="no-content">
      <NoContent />
    </div>
  );

  renderTickets = tickets =>
    tickets.map((ticket, i) => (
      <CSSTransition
        key={ticket.id}
        timeout={1600}
        classNames="ticket-animation"
      >
        <Ticket {...ticket} completeTicket={this.completeTicket} />
      </CSSTransition>
    ));

  render() {
    const { locationName, name, tickets, isLoadingTickets } = this.state;
    return (
      <div className="queue">
        <header className="queue__header">
          <Link to="/">
            <ArrowBack />
          </Link>
          <h2 className="queue__header__name">
            {locationName === "" && name === ""
              ? ""
              : `${locationName} ${name}`}
          </h2>
        </header>
        <main className="queue__main">
          {isLoadingTickets ? (
            this.renderLoading()
          ) : (
            <TransitionGroup className="ticket-list">
              {this.renderTickets(tickets)}
            </TransitionGroup>
          )}
          {!isLoadingTickets && !tickets.length && this.renderComplete()}
        </main>
      </div>
    );
  }
}

export default Queue;
