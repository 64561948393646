import AirBalloonFloating from "./AirBalloonFloating";
import AirplaneLoader from "./AirplaneLoader";
import EmptyBox from "./EmptyBox";
import EmptyClipboard from "./EmptyClipboard";
import Fishing from "./Fishing";
import LittleFerryLoader from "./LittleFerryLoader";

export const noContentIllustrations = [
  AirBalloonFloating,
  EmptyBox,
  EmptyClipboard,
  Fishing
];

export const loadingAnimations = [AirplaneLoader, LittleFerryLoader];
